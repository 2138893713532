import React from 'react';
import { FaCircle } from "react-icons/fa";
import ExpandInfo from './ExpandInfo'


const ResumeCard = (props) => {
  const status = () => {
    if (props.statusColor === "red") {
      return (<span style={{color: "red"}}><FaCircle /></span>)
    } else if (props.statusColor === "yellow") {
      return (<span style={{color: "yellow"}}><FaCircle /></span>)
    } else if (props.statusColor === "green") {
      return (<span style={{color: "green"}}><FaCircle /></span>)
    } else {
        return (<FaCircle />)
    }
  }
  return (
    <div className="card">
      <div className="card-main-header card-header">
        <h5>{props.title} - {props.dateApplied}</h5>
        <h5>({props.statusText}) - Status: {status()}</h5>
      </div>
      <div className="card-body">
        <h6 className="card-title">{props.submittedTo} ({props.postionAppliedFor})</h6>
        <ExpandInfo
          title="View Notes" 
          expand_id={props.submittedTo}
          content={props.notes}
        />
        <a href={props.prefix + props.link} target="_blank" rel="noopener noreferrer" className="btn btn-outline-success btn-sm">View Resume</a>
        <a href={`/polymath/#/collections/notes/entries/${props.link}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-success level-1-margin-left">Add/Update Notes</a>
      </div>
    </div>
  );
};

export default ResumeCard;