import React, { useState } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';

const ExpandInfo = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const title = () => {
    if(isOpen) {
      return "Close Notes"
    } else {
      return props.title
    }
  }

  return (
    <div>
    <Button color="link" size="sm" onClick={toggle} style={{ marginBottom: '1rem' }}>{title()}</Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
          <div dangerouslySetInnerHTML={{__html: props.content}}/>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

export default ExpandInfo;