import React, { useState } from "react"
import MainLayout from 'src/layouts/Main'
import PageTitleHero from 'components/PageTitleHero'
import ResumeCard from 'components/ResumeCard'
import { graphql } from 'gatsby'
import Moment from 'react-moment'
import get from 'lodash/get'
import { TabContent, TabPane, Nav, NavItem, NavLink, Collapse, Button, CardBody, Card, Row, Col } from 'reactstrap';
import classnames from 'classnames';


import { MdSchool } from "react-icons/md";
import { FaAward } from "react-icons/fa";

export default class Resume extends React.Component {
  state = {
    loggedIn: false
  }

  componentDidMount() {
    const validated = localStorage.getItem('key');
    if (validated === "true") {
      this.setState({
        loggedIn: true
      })
    }
  }
  render() {

    const resumesNotes = get(this, 'props.data.allNotesJson.edges')
    const resumes = get(this, 'props.data.allResumeJson.edges')
    const rc =  get(this, 'props.data.allResumeContentJson.edges[0].node')
    const __ =  get(this, 'props.data.allResumeJson.edges[2].node')
 
    const activeCards = resumesNotes.map((note, i)=> {
      if (!note.node.archived) {
        const status = note.node.status === "No Longer Interested" ||
                      note.node.status === "Rejected" ? "red" : note.node.status === "Resume Sent" ? "yellow" : note.node.status === "Interview Scheduled" || note.node.status === "Interviewed" ? "green" : note.node.status === "Accepted" ? "green": null

        return(
          <ResumeCard
            key={i}
            title={note.node.submittedTo}
            prefix="./"
            link={`${note.node.resumeSlug}`}
            submittedTo={note.node.submittedTo}
            postionAppliedFor={note.node.postionAppliedFor}
            notes={note.node.notes}
            statusColor={status}
            statusText={note.node.status}
            dateApplied={ 
              <Moment format="MM/DD/YYYY">
                {note.node.dateApplied}
              </Moment>
            }
          />
        )
      }
    })
    const archivedCards = resumesNotes.map((note, i)=> {

      if (note.node.archived) {
        const status = note.node.status === "No Longer Interested" ||
                      note.node.status === "Rejected" ? "red" : note.node.status === "Resume Sent" ? "yellow" : note.node.status === "Interview Scheduled" || note.node.status === "Interviewed" ? "green" : note.node.status === "Accepted" ? "green": null

        return(
          <ResumeCard
            key={i}
            title={note.node.submittedTo}
            prefix="./"
            link={`${note.node.resumeSlug}`}
            submittedTo={note.node.submittedTo}
            postionAppliedFor={note.node.postionAppliedFor}
            notes={note.node.notes}
            statusColor={status}
            statusText={note.node.status}
            dateApplied={ 
              <Moment format="MM/DD/YYYY">
                {note.node.dateApplied}
              </Moment>
            }
          />
        )
      }
    })

  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (value === "trinitychristiana") {
      localStorage.setItem('key', true);
      this.setState({loggedIn: true});
    }
  }

  const logOut = () => {
    localStorage.setItem('key', false);
    this.setState({loggedIn: false});
  }

  const pageTitle = () => {
    if (this.state.loggedIn) {
      return "Resume <span>List</span>"
    } else {
      return "Work <span>with Me!</span>"
    }
  }

  const section_items = (section_data, section_name, extra) => { 
    // WORK 
    if(section_name === "work") {
      return section_data.work.map((item, i)=> {
          return (
            <div className="list_block_flexed level-2-margin-bottom">
              <div key={i} className="list_block">
                <b>{ item.company } - {item.location}</b><br />
                {item.position}
              </div>
            </div>
          )
      })
    }

    // SKILLS 
    if(section_name === "skills") {
       return section_data.skills.map((item, i) => {
        if (extra === item.type) {
          return ( 
              <span key={i} className="skill-item">{ item.name }</span>
          )
        }
        return ""
      })
    }

    // EDUCATION 
    if(section_name === "education") {
      return section_data.education.map((item, i)=> {
        return (
          <div key={i} className="list_block ed_item level-2-margin-bottom">
            <b><MdSchool /> { item.institution } - {item.location}</b><br />
            {item.studyType}
          </div>
        )
      })
    }

    // AWARDS 
    if(section_name === "awards") {
      return section_data.awards.map((item, i)=> {
        return (
          <div key={i} className="list_block level-2-margin-bottom">
            <FaAward /> {item.date} - {item.title}
            <br/>
              <a className="color-link" href={item.website} target="_blank" rel="noopener noreferrer">{item.awarder}</a>
          </div>
        )
      })
    }
  }

  const DOMState = () => {
    if (this.state.loggedIn) {
      return (
        <div className="flex-content-container">
          <div className="col-one">
            <Tabs 
              active={activeCards}
              archived={archivedCards}
            />
          </div>
          <div className="col-two">
            <h3>Command Center</h3>
            <hr />
            <p>View all the resumes you have in the system. To add a resume, create a JSON file under <code>DynamicContent > resume</code>.</p>
            <div>
              <ResumeToggle 
                resumes={resumes}
              />
            </div>
            <hr />
            <p>Got an interview coming up? Or, perhaps you received an email with details about the position?</p>
            <a className="btn btn-secondary btn-block" target="_blank" href="/polymath/#/collections/notes/new" rel="noopener noreferrer" >Add Job Notes</a>

            <hr />
            <p>Need to add or update some volunteer time?</p>
            <a className="btn btn-secondary btn-block" target="_blank" href="/polymath/#/collections/volunteering" rel="noopener noreferrer" >Add/Update Volunteer Work</a>

            <hr />
            <p>Add a new item or make updates to your portfolio.</p>
            <a className="btn btn-secondary btn-block" target="_blank" href="/polymath/#/collections/portfolio" rel="noopener noreferrer" >Add/Update Portfolio</a>

            <hr />
            <button className="btn btn-info btn-block" onClick={logOut}>Log Out</button>
          </div>
        </div>
        )
     } else {
      return ( 
        <div className="flex-content-container">
          <div className="col-one">

            {/* SKILLS */}
            <div className="resume_section">
              <h3>Skills</h3>
                <div className="skills">
                  
                  <div className="list_block skills-group">
                    <span className="skill-titles">Frameworks: </span>
                    {section_items(rc, "skills", "framework")}
                  </div>

                  <div className="list_block skills-group">
                    <span className="skill-titles">Platforms: </span>
                    {section_items(rc, "skills", "platform")}
                  </div>
                  
                  <div className="list_block skills-group">
                    <span className="skill-titles">Software Tools: </span>
                    {section_items(rc, "skills", "tool")}
                  </div>

                  <div className="list_block skills-group">
                    <span className="skill-titles">Deployment Tools: </span>
                    {section_items(rc, "skills", "deployment")}
                  </div>

                  <div className="list_block skills-group">
                    <span className="skill-titles">Languages: </span>
                    {section_items(rc, "skills", "language")}
                  </div>

                  <div className="list_block skills-group">
                    <span className="skill-titles">Libraries: </span>
                    {section_items(rc, "skills", "library")}
                  </div>

                  {/* <div className="list_block skills-group">
                    <span className="skill-titles">PM Tools: </span>
                    {section_items(rc, "skills", "pm-tool")}
                  </div> */}

                  {/* <div className="list_block skills-group">
                    <span className="skill-titles">Design Tools: </span>
                    {section_items(rc, "skills", "design-tool")}
                  </div> */}

                </div>
            </div>

            <hr />

            {/* RELEVANT EXPERIENCE */}
            {/* <div className="resume_section">
                <h3>Relevant Experience</h3>
                  {section_items(__, "work")}
            </div> */}

            {/* <hr /> */}
            <div className="resume_section">
              <h3>Education</h3>
                <div className="education">
                  {section_items(rc, "education")}
                </div>
            </div>
            <hr />

            {/* AWARDS */}
            <h3 className="section_heading">Awards</h3>
                {section_items(rc, "awards")}


          </div>
          <div className="col-two">
          <h3>Learn More</h3>
            <hr />
            <p><a className="color-link" href="/resume/main" target="_blank" rel="noopener noreferrer"><b>View My Full Resume</b></a></p>
            <p><a href="/resume/main" target="_blank" rel="noopener noreferrer"><img src="/images/resume-preview.png" alt="Resume Screen Shot" width="90%" /></a></p>
            <hr />
            <label htmlFor="password">Login</label>
            <input 
              className="form-control" 
              type="password"
              name="password"
              placeholder="*********"
              id="password"
              required={true}
              onChange={handleChange} 
            />
          </div>
        </div>
        )
     }
  }

  return(
    <MainLayout>
      <PageTitleHero 
        title={pageTitle()}
        class="resume-hero"
      />
      {DOMState()}
    </MainLayout>
  )
  }
}

const ResumeToggle = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const resumeList = () => {    
    return props.resumes.map((resume, i) => {
      const slug = (resume.node.title).toLowerCase().split(" ").join("-")

      return <li key={i}><a href={slug} target="_blank" rel="noopener noreferrer">{resume.node.title}</a> <small className="text-muted">(slug: {slug})</small></li>
    })
  }

  return (
    <div>
      <Button block color="secondary" onClick={toggle} style={{ marginBottom: '1rem' }}>See All Resumes</Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            {resumeList()}
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}


const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Active Resumes
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Archived
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              {props.active}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              {props.archived}
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}


export const pageQuery = graphql`
  query {
    allNotesJson (sort: { fields: [dateApplied], order: DESC }) {
      edges {
        node {
          submittedTo
          postionAppliedFor
          notes
          dateApplied
          status
          resumeSlug
          archived
        }
      }
    }

    allResumeJson {
      edges {
        node {
          title
          templateKey
          work {
            company
            position
            location
          }
        }
      }
    }

    allResumeContentJson(filter: {templateKey: {eq: "dev-resume-constants"}}){
      edges {
        node {
          basics {
            website {
              text
              url
            }
            github {
              text
              url
            }
            linkedin {
              text
              url
            }
          }
          education {
            institution
            location
            studyType
            emphasis
          }
          skills {
            name
            level
            type
          }
          awards {
            title
            date
            awarder
            summary
            website
          }
        }
      }
    }

  }
`
